import React, {useState, useCallback} from 'react';
import FormContext from './impl/FormContext';
import FormStyles from './impl/FormStyles.module.css';

const empty = () => {};

export default function Form({
  onSubmit: onFormSubmit = empty,
  children,
  onChange = empty,
}) {
  const [values, setValues] = useState({});

  const onSubmit = useCallback(
    event => {
      if (event != null) {
        event.preventDefault();
      }

      onFormSubmit(values);
    },
    [onFormSubmit, values],
  );

  const onFieldChange = useCallback(
    event => {
      const {target} = event;
      const {name} = target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      setValues(curr => {
        const newValues = {...curr, [name]: value};
        onChange(newValues);
        return newValues;
      });
    },
    [onChange],
  );

  const setDefault = useCallback((name, value) => {
    setValues(curr => {
      if (curr[name] == null) {
        return {...curr, [name]: value};
      }
    });
  }, []);

  return (
    <form className={FormStyles.Form} onSubmit={onSubmit}>
      <input type="hidden" value="Make autocomplete work properly" />
      <FormContext.Provider
        value={{onChange: onFieldChange, values, setDefault}}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
}
