import {useContext, useEffect} from 'react';
import FormContext from './FormContext';

export default function useDefault({name, defaultValue}) {
  const {values, setDefault} = useContext(FormContext);

  useEffect(() => {
    setDefault(name, defaultValue);
  }, [name, setDefault, defaultValue]);

  return values[name] == null ? defaultValue : values[name];
}
