import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';

import Browser from './Browser.react';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Browser />
        </Route>
      </Switch>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
