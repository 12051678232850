import React, {useContext} from 'react';
import FormContext from './impl/FormContext';
import useDefault from './impl/useDefault';
import FormStyles from './impl/FormStyles.module.css';

export default function TextInput({
  name,
  label,
  placeholder = '',
  autoFocus = false,
  disabled = false,
  defaultValue = '',
}) {
  const {onChange} = useContext(FormContext);
  const value = useDefault({name, defaultValue});

  const input = (
    <input
      autoComplete="off"
      autoFocus={autoFocus}
      className={FormStyles.TextInput}
      disabled={disabled}
      name={name}
      placeholder={placeholder}
      type="text"
      value={value}
      onChange={onChange}
    />
  );

  return (
    <label style={{maxWidth: '100%'}}>
      <div className={FormStyles.Label}>{label}</div>
      {input}
    </label>
  );
}
