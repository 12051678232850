import React, {useEffect, useState, useRef} from 'react';
import TextInput from './form/TextInput.react';
import Form from './form/Form.react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

import monsters from './shared/monsters.json';

export default function Browser() {
  const [constraints, setConstraints] = useState({});
  const [results, setResults] = useState([]);

  // Debounce the search since it makes things chug.
  const timeout = useRef(null);
  useEffect(() => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      timeout.current = null;
      setResults(searchResults(constraints));
    }, 500);
  }, [constraints]);

  const [selected, setSelected] = useState(null);

  const [pinned, setPinned] = useLocalStorageState([]);

  const addToPinned = id => setPinned([...pinned.filter(x => x !== id), id]);
  const removeFromPinned = id => setPinned([...pinned.filter(x => x !== id)]);

  // Preload pinned statblocks
  useEffect(() => {
    pinned.forEach(id => {
      new Image().src = statblockUrl(id);
    });
  }, [pinned]);

  const pinnedTable =
    pinned.length > 0 ? (
      <>
        <table>
          <ResultsHeader />
          <tbody>
            {pinned.map(fid => (
              <ResultEntry
                key={fid}
                {...monsters.find(m => m.fid === fid)}
                onSelect={setSelected}
                addToPinned={addToPinned}
                removeFromPinned={removeFromPinned}
                pinned={pinned}
              />
            ))}
          </tbody>
        </table>
      </>
    ) : null;

  const table =
    results.length > 0 && selected == null ? (
      <table>
        <ResultsHeader />
        <tbody>
          {results.map(r => (
            <ResultEntry
              key={r.fid}
              {...r}
              onSelect={setSelected}
              addToPinned={addToPinned}
              removeFromPinned={removeFromPinned}
              pinned={pinned}
            />
          ))}
        </tbody>
      </table>
    ) : null;

  const selection =
    selected != null ? (
      <div className="selection">
        <h2 style={{display: 'flex', justifyContent: 'space-between'}}>
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setSelected(null)}
          >
            <div
              style={{
                marginRight: '12px',
                display: 'inline-block',
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            {monsters.find(m => m.fid === selected).name}
          </span>
          <PinButton
            id={selected}
            addToPinned={addToPinned}
            removeFromPinned={removeFromPinned}
            pinned={pinned}
          />
        </h2>
        <SlowImage key={selected} src={statblockUrl(selected)} />
      </div>
    ) : null;

  return (
    <div className="Browser">
      <div className="form-container">
        <h1>Let's Find You A Monster</h1>
        {pinnedTable}
        {selection == null ? (
          <Form onChange={setConstraints}>
            <TextInput
              label="Name"
              name="name"
              placeholder="Let us dive into the depths of my bestiary..."
            />
          </Form>
        ) : null}
      </div>
      {selection}
      {table}
    </div>
  );
}

function ResultsHeader() {
  return (
    <thead>
      <tr>
        <th>Name</th>
        <th>CR</th>
        <th>Size</th>
        <th>Type</th>
        <th />
        <th />
      </tr>
    </thead>
  );
}

function PinButton({addToPinned, removeFromPinned, pinned, id}) {
  const isPinned = pinned.includes(id);
  const togglePin = () => (isPinned ? removeFromPinned(id) : addToPinned(id));

  return (
    <span
      style={{cursor: 'pointer', textDecoration: 'underline'}}
      onClick={togglePin}
    >
      <div style={{marginRight: '4px', display: 'inline-block'}}>
        <FontAwesomeIcon icon={faThumbtack} />
      </div>
      {isPinned ? 'Un-pin' : 'Pin'}
    </span>
  );
}

function ResultEntry({
  fid,
  name,
  size,
  cr,
  type,
  onSelect,
  addToPinned,
  removeFromPinned,
  pinned,
}) {
  return (
    <tr>
      <td>{name}</td>
      <td>{cr}</td>
      <td>{size}</td>
      <td>{type}</td>
      <td>
        <PinButton
          id={fid}
          addToPinned={addToPinned}
          removeFromPinned={removeFromPinned}
          pinned={pinned}
        />
      </td>
      <td>
        <span
          style={{cursor: 'pointer', textDecoration: 'underline'}}
          onClick={() => onSelect(fid)}
        >
          Statblock
          <div style={{marginLeft: '4px', display: 'inline-block'}}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </span>
      </td>
    </tr>
  );
}

function SlowImage({src}) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {isLoading ? <div className="spinner" /> : ''}
      <img
        style={{display: isLoading ? 'none' : 'block', width: '100%'}}
        onLoad={() => setIsLoading(false)}
        src={src}
        alt=""
      />
    </>
  );
}

function statblockUrl(id) {
  return `https://api.bezi.io/statblocks/${id}.png`;
}

function searchResults(constraints) {
  let candidates = monsters.slice();
  let isBlank = true;

  const {name, minCr, maxCr} = constraints;

  if (name != null && name !== '') {
    isBlank = false;
    candidates = candidates.filter(m =>
      m.name.toLowerCase().includes(name.toLowerCase()),
    );
  }

  if (minCr != null && minCr !== '' && !Number.isNaN(parseInt(minCr, 10))) {
    isBlank = false;
    const parsed = parseInt(minCr);
    candidates = candidates.filter(m => m.cr >= parsed);
  }

  if (maxCr != null && maxCr !== '' && !Number.isNaN(parseInt(maxCr, 10))) {
    isBlank = false;
    const parsed = parseInt(maxCr);
    candidates = candidates.filter(m => m.cr <= parsed);
  }

  return isBlank ? [] : candidates;
}

function useLocalStorageState(defaultValue, key) {
  const [value, setValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item == null ? defaultValue : JSON.parse(item);
    } catch (error) {
      window.localStorage.removeItem(key);
      console.log('unable to read from localstorage.  Error: ', error);
      return defaultValue;
    }
  });

  const setAndWriteToStorage = newValue => {
    window.localStorage.setItem(key, JSON.stringify(newValue, null, 2));
    setValue(newValue);
  };

  return [value, setAndWriteToStorage];
}
